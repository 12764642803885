.TitleClientAllDataShow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem;
  align-items: center;
}

.ButtonsClientAllDataShow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.ButtonsClientAllDataShow > button {
  height: 2.5rem;
}

.return_button_data {
  width: 7.5rem;
  height: 2.5rem;
  background-color: var(--primary-color);
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LeftArrowClientAllDataShow {
  width: 1rem;
  margin-right: 0.8rem;
}

/* Actions */
.content_actions_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-flow: row-reverse;
}

.more_options_view {
  border: none;
  background-color: transparent;
}

.more_options_view:hover,
.more_options_view:focus,
.more_options_view:active {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
}

.options_to_tab {
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
}

.options_to_tab:hover,
.options_to_tab:focus,
.options_to_tab:active {
  background-color: #ececec;
}

/* Edit popover  */
.ant-popover .ant-popover-inner {
  box-shadow: 0 2px 4px -8px rgba(26, 25, 25, 0.24),
    0 5px 16px 0 rgba(21, 21, 21, 0.16), 0 9px 28px 2px rgba(34, 34, 34, 0.11) !important;
}

.ant-popover .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-color: white !important;
}

.ant-tabs-content-holder {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 10px 0;
}

@media (max-width: 575.98px) {
  .TitleClientAllDataShow {
    flex-wrap: wrap;
  }

  .TitleClientAllDataShow > div {
    width: 100%;
  }

  .ButtonsClientAllDataShow {
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 1.6rem !important;
  }

  .ButtonsClientAllDataShow > button {
    padding: 0.8rem 1rem !important;
  }

  .return_button_data {
    margin: 0;
  }

  .details_btnPDF {
    display: none;
  }
}

/* Edit bootstrap */
@media (max-width: 768px) {
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:active,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:focus {
    border-bottom: 0;
    border-left: 3px solid #0066f9;
    padding-left: 13px !important;
  }

  .navbar-nav:last-child li:nth-child(n + 2):hover,
  .navbar-nav:last-child li:nth-child(n + 2):active,
  .navbar-nav:last-child li:nth-child(n + 2):focus {
    color: #0066f9;
  }
}
