.CarouselExperts {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.TitleCarouselExperts {
  color: #303030;
  font-weight: normal;
  font-size: clamp(1em, 1.1em, 1.2em);
  padding-bottom: 1em;
  /* text-decoration: underline; */
}

.CarouselExpertsImages {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.Info {
  margin-top: 2rem;
  color: var(--gray-color);
}

.InfoTitle {
  font-weight: 600;
  color: #1e1b50;
  font-size: clamp(0.9em, 1em, 1.1em);
}

.InfoBody {
  font-size: clamp(0.75em, 0.8em, 0.9em);
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.FrontImageCarouselExperts {
  max-width: 28rem;
  max-height: 18rem;
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.slideshow-container h4 {
  color: #1e1b50;
  font-size: 1em;
  font-weight: 550;
}

/* Show image */
.slideEnable {
  display: block;
}

/* Hide image */
.slideDisable {
  display: none;
}

.dots {
  text-align: center;
  margin: 0.5rem 0 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  /* border: 1px solid rgb(151, 151, 151); */
  border: none;
  background-color: #b9b9b9;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #3742d4;
  outline: none;
  border: none;
}

.ImagesCarouselExperts {
  max-width: 30rem;
  max-height: 20rem;
}

.ImagesCarouselExperts:hover {
  cursor: pointer;
}

@media only screen and (max-width: 670px) {
  .ImagesCarouselExperts {
    width: 21rem;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1024px) {
  .ImagesCarouselExperts {
    width: 20rem;
  }
}
