.mainDiv {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  cursor: pointer;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-repeat: no-repeat;
  object-fit: contain;
}

.img {
  position: absolute;
  z-index: 90;
}

.internalDiv {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  align-items: center;
  background-repeat: no-repeat;
  object-fit: contain;
}

.date {
  position: absolute;
  z-index: 100;
}

.rightDiv {
  display: inline-flex;
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.rightDiv_Close {
  display: inline-flex;
  background-color: tomato;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  border-radius: 100%;
  position: relative;
  z-index: 100;
}

.rightDiv_Close:active,
.rightDiv_Close:focus,
.rightDiv_Close:hover {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
}

.rightDiv_AddSign {
  display: flex;
  background-color: #f89803;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  border-radius: 100%;
  position: relative;
  z-index: 100;
  border: none;
  outline: none;
}

.rightDiv_AddSign:active,
.rightDiv_AddSign:focus,
.rightDiv_AddSign:hover {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
}

.rightDiv_Rubric {
  display: inline-flex;
  background-color: #388e3c;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  padding-bottom: 2px;
  border-radius: 100%;
  position: relative;
  z-index: 100;
}

.taskB {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  cursor: pointer;
  padding: 0px 10px;
}
