.PlatformLabelWithInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.PlatformLabelWithInfo-label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 2.5rem;
  margin: 0.5rem;
}

.PlatformLabelWithInfo-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.PlatformLabelWithInfo-profile {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.PlatformLabelWithInfo-image {
  width: 7rem;
  height: 7rem;
}

.PlatformLabelWithInfo-contact-info {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.PlatformLabelWithInfo-more-info {
  display: flex;
  flex-direction: column;
  margin: 0.8rem;
}
