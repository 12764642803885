.messageMain {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  cursor: pointer;
}

.messageLeft {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}

.messageDiv {
  display: inline-flex;
  color: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.messageDiv_Close {
  display: inline-flex;
  background-color: tomato;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  border-radius: 100%;
  position: relative;
  z-index: 100;
}
