.HomeSetup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 2em;
  /* background-color: #172b4d; */
}

.content_options_home {
  display: inline-flex;
}

.content_options_home > button {
  outline: none;
  border: none;
  background: transparent;
  margin-left: 10px;
  padding: 0 3px;
}

.content_options_home > button:hover,
.content_options_home > button:focus,
.content_options_home > button:active {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
}

/* Components  - modal*/
.modal_card {
  text-align: center;
  border-radius: 5px !important;
  padding: 1rem 0 !important;
}

.modal_card > .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ant-modal-title {
  font-size: clamp(1em, 1.6em, 1.8em) !important;
  color: #172b4d !important;
}

.ant-modal-close-x {
  line-height: 47px !important;
  height: 55px !important;
}

/* Body */
.ant-modal-body {
  /* background-color: #f1f1f1; */
  padding: 40px 30px 40px 30px !important;
}
.ant-card-body {
  padding: 24px 8px;
}
.ant-card-hoverable {
  box-shadow: #0000001a 0px 4px 12px;
}

.ant-card-hoverable:hover,
.ant-card-hoverable:focus,
.ant-card-hoverable:active {
  background-color: #f186aa2c !important;
  border-color: #f186aa !important;
  box-shadow: none !important;
}
/* .ant-card-bordered {
  border: 2px solid #e6e6e6 !important;
} */
/* Icon */
.icon__modal_card {
  width: 25px;
  height: 25px;
  margin: 10px 0 0 0;
  color: #172b4d;
}

/* Buttons */
#btn-sdk-close,
#btn-sdk-cancel {
  font-weight: 100;
  background-color: transparent;
  color: #ff84ad;
  border: 1.9px solid #ff84ad;
  transition: 0.3s color ease, 0.3s background-color ease;
}

#btn-sdk-close:hover,
#btn-sdk-close:active,
#btn-sdk-close:focus,
#btn-sdk-cancel:hover,
#btn-sdk-cancel:active,
#btn-sdk-cancel:focus {
  background-color: #ff84ad;
  color: white;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

#btn-sdk-submit {
  font-weight: 300;
  background-color: #4e52d8;
  color: white;
  margin-left: 1em;
  transition: 0.3s color ease, 0.3s background-color ease;
}

#btn-sdk-submit:hover,
#btn-sdk-submit:active,
#btn-sdk-submit:focus {
  background-color: #34379c;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

/* Modal success */
.success_modal_sdk .ant-modal-content .ant-modal-confirm-btns {
  display: block;
}

/* Modal error sdk */
.error_modal_sdk .ant-modal-content .ant-modal-body {
  padding: 40px 25px 20px 25px !important;
}
.error_modal_sdk .ant-modal-content .ant-modal-confirm-btns {
  display: block;
}

/* Button verificate */
.btn_custom_verificate {
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px;
  transition: 0.6s background-color ease, 0.6s color ease !important;
}
.btn_custom_verificate:hover,
.btn_custom_verificate:focus,
.btn_custom_verificate:focus-visible,
.btn_custom_verificate:active {
  background-color: var(--primary-color) !important;
  border: 1px solid #40a9ff !important;
  border-radius: 5px;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
  outline: none !important;
}

.switch_sdk.ant-switch-checked {
  background-color: #1890ff !important;
}

/* Collapse more options */
.view_more_optios_verify.collapsed {
  display: none;
}

.view_more_optios_verify.expanded {
  display: flex;
}

.ui.page.modals.dimmer.transition.visible.active {
  /* z-index: 99999999; */
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ui.modal.transition.visible.active.accept-masive {
  height: auto;
  width: 38%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 500px;
  overflow-y: auto;
  box-shadow: none !important;
}

.masive-tooltip {
  z-index: 999999999;
}

@media (max-width: 767px) {
  .ui.modal.transition.visible.active.accept-masive {
    width: 90%;
  }
  .ui.modal.transition.visible.active.accept-masive > .actions {
    padding-bottom: 1rem !important;
  }
}
