.signers_Li {
  margin: 1rem 0;
}
.signers_Li > input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 17px;
  height: 17px;
  border: 1.4px solid #c5c5c5;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.signers_Li > input[type='checkbox']:checked {
  background-color: var(--secondary-color);
  outline: none;
}
.signers_Li > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: #fff;
}

.signers_Li > input[type='checkbox']:hover,
.signers_Li > input[type='checkbox']:focus,
.signers_Li > input[type='checkbox']:active {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}
.signers_Li > input[type='checkbox']:checked {
  background-color: var(--secondary-color);
  outline: none;
}

.signers_Li > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.signers_Li > input[type='checkbox']:disabled {
  outline: none;
  cursor: not-allowed;
}
