.Filter {
  margin: 1rem 0 2rem;
  padding: 1rem 1.3rem;
  background-color: #f8f8f8;
}

.content_inputs_expert {
  display: grid;
  row-gap: 10px;
  column-gap: 17px;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}

.text_input_expert {
  border: 1px solid #d9d9d9;
  padding: 0.48rem 0.8rem;
  width: 100%;
}

.content_actions_expert {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 14px;
  margin: 1rem 0 0;
}
