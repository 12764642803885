/* .RegisterFilters {
  display: flex;
} */

.anticon {
  vertical-align: 0.1em !important;
}

.ant-input-search-button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

/* .RegisterFiltersInput {
  margin: 0 4rem 0 0;
}

@media (max-width: 768px) {
  .RegisterFilters {
    margin-bottom: 1.5rem;
  }

  .RegisterFiltersInput {
    margin: 0 0.8rem 0 0;
  }
} */

@media (max-width: 460px) {
  .RegisterFilters {
    background-color: aquamarine;
    margin-bottom: 1rem;
  }
}
