.btn_add_sign {
  padding: 0.3rem 1rem;
  border-radius: 3px;
  background-color: white;
  font-size: clamp(0.83rem, 0.8rem, 0.9rem);
  border: 1px solid #3f3ca4;
  color: #3f3ca4;
}

.btn_add_sign:hover,
.btn_add_sign:active {
  border: 1px solid #3f3ca4;
  background-color: #3f3ca4;
  color: #fff;
}

.item_save_signs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}

.item_save_signs:hover {
  background-color: #ececec;
}

.btn_save_signs {
  border: solid var(--secondary-color) 1px;
  padding: 0.3rem 1.6rem;
  color: #fff;
  background-color: var(--secondary-color);
  border-radius: 3px;
}
