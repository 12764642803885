.FormatData {
  margin-top: 1rem;
  text-align: start;
  width: 100%;
}

.FormatDataTitle {
  color: #1e1b50;
  font-weight: normal;
  font-size: 1rem;
  text-decoration: underline;
}

.EditMRZ {
  display: flex;
}

.LeftEditMRZ {
  width: 66.3%;
}

.LeftEditMRZ input {
  width: 100%;
  margin: 1rem 0;
  font-size: 1rem;
  letter-spacing: 1.105rem;
  padding-left: 0.5rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1230px) {
  .LeftEditMRZ input {
    letter-spacing: 0.65rem;
  }
}

@media only screen and (min-width: 1230px) and (max-width: 1250px) {
  .LeftEditMRZ input {
    letter-spacing: 0.7rem;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1280px) {
  .LeftEditMRZ input {
    letter-spacing: 0.73rem;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1320px) {
  .LeftEditMRZ input {
    letter-spacing: 0.77rem;
  }
}

@media only screen and (min-width: 1320px) and (max-width: 1350px) {
  .LeftEditMRZ input {
    letter-spacing: 0.82rem;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1390px) {
  .LeftEditMRZ input {
    letter-spacing: 0.852rem;
  }
}

@media only screen and (min-width: 1390px) and (max-width: 1410px) {
  .LeftEditMRZ input {
    letter-spacing: 0.89rem;
  }
}

@media only screen and (min-width: 1410px) and (max-width: 1440px) {
  .LeftEditMRZ input {
    letter-spacing: 0.93rem;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1480px) {
  .LeftEditMRZ input {
    letter-spacing: 0.96rem;
  }
}

@media only screen and (min-width: 1480px) and (max-width: 1520px) {
  .LeftEditMRZ input {
    letter-spacing: 1rem;
  }
}

@media only screen and (min-width: 1520px) and (max-width: 1550px) {
  .LeftEditMRZ input {
    letter-spacing: 1.04rem;
  }
}

@media only screen and (min-width: 1550px) and (max-width: 1590px) {
  .LeftEditMRZ input {
    letter-spacing: 1.08rem;
  }
}

@media only screen and (min-width: 1590px) and (max-width: 1600px) {
  .LeftEditMRZ input {
    letter-spacing: 1.1rem;
  }
}

.TableNumbers {
  width: 100%;
  text-align: center;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  opacity: 1;
   
}

@media only screen and (max-width: 760px) {
  .TableNumbers {
    display: none;
  }
}

.RowsNumbers {
  width: 2%;
  border-right-style: outset;
  color: #1e1b50;
   
  font-weight: normal;
  font-size: 0.79rem;
}

.RightEditMRZ {
  width: 32.7%;
  margin: auto;
  text-align: center;
}

.RightEditMRZ img {
  width: 22rem;
}

@media only screen and (max-width: 1200px) {
  .EditMRZ {
    flex-direction: column;
  }

  .LeftEditMRZ {
    width: 100%;
  }

  .RightEditMRZ {
    width: 100%;
    text-align: start;
    margin: 1.4rem 0;
  }
}

.ButtonVerifyMRZ {
  width: 15rem;
  background-color: #222557;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonVerifyMRZ:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.TableMRZ {
  margin: 1rem 0 0 0;
}

.ClassTD {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: center;
  padding: 8px;
}

.ClassTH {
  border-top: 1px solid rgb(148, 148, 148);
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: center;
  padding: 8px;
  font-weight: 500;
  color: #1e1b50;
}

.Table {
  width: 100%;
  margin: 0;
  background-color: #ebebeb;
}

.Correct > .InputFields {
  color: #01a07b;
  font-size: 1rem;
}

.Incorrect > .InputFields {
  color: #a50434;
  font-size: 1rem;
}

.Override > .InputFields {
  color: #dd8e18;
  font-weight: normal;
  font-size: 1rem;
}

.InputFields {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.EditButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.OverwriteButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #738d00;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.CancelButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #c10841;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.FormatOutput:hover {
  cursor: pointer;
}
