.UploadImages {
  margin: 1rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.UploadFront {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.UploadBack {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.ButtonConfirm {
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  background-color: #3f43b8;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.ButtonConfirm:hover,
.ButtonConfirm:active,
.ButtonConfirm:focus {
  outline: none;
  background-color: #2d308a;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.ButtonConfirm:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.IconFront {
  position: absolute;
  width: 2rem;
  margin-left: 54px;
  margin-top: 33px;
}

.IconBack {
  position: absolute;
  width: 2rem;
  margin-left: 54px;
  margin-top: 33px;
}
