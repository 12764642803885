/* Form add new signatory or observer */
.form_add {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 1.3em 1.2em 1.6em;
  margin-top: 1.2rem;
}

.content-input_add {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  padding: 0.5em 0;
}

.content-input_search {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.content_search > input,
.content-input_add > input {
  border: 1px solid #d9d9d9;
  padding: 0.5em 0.7em;
  outline: none;
  transition: all 0.3s;
  color: gray;
}

/* Search */
.content_search {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.content_search > input {
  width: 90%;
}

.show_search_uuid {
  outline: none !important;
  border: none !important;
  background: transparent;
  height: 100%;
}

.show_search_uuid.rotateOrigin {
  transform: rotate(0deg);
}

.show_search_uuid.rotate90 {
  transform: rotate(90deg);
}

.content_search > .btn_action_search {
  outline: none;
  border: none;
  background-color: #faa628;
  width: 10%;
}

.content_search > .btn_action_search:hover,
.content_search > .btn_action_search:active {
  outline: none;
  border: none;
  background-color: var(--secondary-color);
}

.content_label_search {
  width: 100%;
  display: flex;
  margin: 0 0 0.5rem 0;
  align-items: center;
  justify-content: space-between;
}

.content_label_search > label {
  margin: 0;
  padding: 0;
}

/* Custom checked */
.content_custom_checked {
  position: relative;
}

.content_custom_checked > input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 17px;
  height: 17px;
  border: 1.4px solid #c5c5c5;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.content_custom_checked > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: #fff;
}

.content_custom_checked > input[type='checkbox']:hover,
.content_custom_checked > input[type='checkbox']:focus,
.content_custom_checked > input[type='checkbox']:active {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}
.content_custom_checked > input[type='checkbox']:checked {
  background-color: var(--secondary-color);
  outline: none;
}

.content_custom_checked > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.content_custom_checked > input[type='checkbox']:disabled {
  outline: none;
  cursor: not-allowed;
}

/* description text */
.description-input_add {
  color: #8f8f8f;
  margin-bottom: 1.3rem;
}

/* Effects input */
.content_search > input:hover,
.content_search > input:focus,
.content-input_add > input:hover,
.content-input_add > input:focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

.content_main_data_add,
.actions_add {
  width: 100%;
}

.actions_add {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.actions_add button {
  border: none;
  padding: 0.25rem 0.5rem 0.35rem 0.5rem;
  border-radius: 5px;
}

.new_add {
  background-color: var(--secondary-color) !important;
  margin-right: 10px;
  outline: none !important;
  border: none;
}

.ui.button.new_add {
  padding: 0.25rem 0.5rem 0.35rem 0.5rem;
  margin: 0 10px 0 0;
}

.new_add:hover,
.new_add:active,
.new_add:focus {
  background-color: var(--secondary-color) !important;
}

.cancel_add {
  outline: none !important;
  border: none;
  background-color: #ff6363;
}

.cancel_add:hover,
.cancel_add:active,
.cancel_add:focus {
  background-color: #ff4141;
}

.cancel_add:disabled {
  background-color: #cf4747;
}

/* Error */
.message_error {
  transition: all 0.5s ease;
  margin-top: 1em;
  width: 100%;
  background-color: #fff7f7;
  color: #ff6363;
  display: flex;
  flex-direction: column;
  padding: 0.7em 1em;
  border-radius: 3px;
  border: 1px solid #ff9a9a;
}

/* Collapse */
.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}

/* Collapse search uuid*/
.content_search.collapsed {
  display: none;
}

.content_search.expanded {
  display: flex;
}

/* Other options */

.select_other_options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  margin: 1rem 0 0 0;
}

.info_select_verification {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 14px;
}

.info_select_verification > span {
  margin-left: 5px;
}
