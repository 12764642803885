.divLi {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px 0;
  border: 1px solid #c4c4c4;
  padding: 0.9rem 1rem;
  font-size: clamp(0.75rem, 0.8rem, 0.85rem);
}

.divLi:hover,
.divLi:active,
.divLi:focus {
  border: 1px solid var(--secondary-color);
}

.spanNameSigner {
  color: #646464;
}
.spanEmailSigner {
  color: #646464;
}

.contentInfoAddSign {
  width: 85%;
  display: flex;
  flex-direction: column;
}
