.EditDocumentData {
  margin-top: 1rem;
  text-align: start;
  width: 100%;
}

.EditDocumentData input {
  width: 98%;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}

.EditTable {
  width: 96%;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  opacity: 1;
}

.RowsEditTable {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  color: #1e1b50;

  font-weight: normal;
  font-size: 1rem;
}

.AddDocumentData {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
}

.AddDocumentData p {
  color: #1e1b50;
  font-size: 1rem;
  opacity: 1;
}

.TableAddDocumentData {
  width: 96%;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  opacity: 1;
}

.RowsAddDocumentData {
  border: 1px solid #dddddd;
  text-align: start;
  padding: 8px;
  color: #1e1b50;

  font-weight: normal;
  font-size: 1rem;
}

.Select {
  height: 1.8rem;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}

.ButtonDelete {
  width: 5rem;
  background-color: #c10841;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}

.ButtonAdd {
  width: 5rem;
  margin: 1rem 0;
  background-color: #738d00;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}

.ButtonSave {
  width: 5rem;
  margin-right: 1rem;
  background-color: #222557;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}

.ButtonCancel {
  width: 5rem;
  background-color: #f89406;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}
