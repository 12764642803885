.Button {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.Button button {
  padding: 0.5rem 0.4rem;
  /* height: 2rem; */
  /* margin-left: 0.1rem; */
  border: none;
  background-color: #f2f2f2;
  color: #000;
  text-align: start;
  width: 100%;
   
}

.Button button:hover,
.Button button:active,
.Button button:focus {
  outline: none;
  background-color: #c9c9c9;
}

.Button img {
  width: 10px;
  margin-right: 10px;
}

.ButtonFailed {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.ButtonFailed button {
  padding: 0.5rem 0.4rem;
  /* margin-left: 0.1rem; */
  border: none;
  width: 100%;
  background-color: #dd3a6b;
  color: #fff;
  text-align: start;
   
}

.ButtonFailed button:hover,
.ButtonFailed button:active,
.ButtonFailed button:focus {
  outline: none;
  background-color: #c10841;
}

.ButtonFailed img {
  width: 10px;
  margin-right: 10px;
}

.ButtonText {
  display: flex;
  align-items: center;
  width: 90%;
}

@media only screen and (max-width: 670px) {
  .ButtonText {
    width: 90%;
  }
}

@media only screen and (min-width: 670px) and (max-width: 850px) {
  .ButtonText {
    width: 95%;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1200px) {
  .ButtonText {
    width: 96.5%;
  }
}
