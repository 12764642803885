.AdditionalVerifications-header {
  /* background-color: #26285a !important; */
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  letter-spacing: 0.5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.AdditionalVerifications-header h2,
.AdditionalVerifications-header h3 {
  margin: 0;
  font-size: clamp(1em, 1.2em, 1.1em) !important;
  color: #fff;
}

.AdditionalVerifications-header .reset_btn {
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: rgba(236, 236, 236, 0.055);
  transition: all ease 0.5s;
}

.AdditionalVerifications-header .reset_btn:hover,
.AdditionalVerifications-header .reset_btn:active,
.AdditionalVerifications-header .reset_btn:focus {
  background-color: rgba(236, 236, 236, 0.24);
}
