.title_header_custom_view_data {
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
  padding: 0.78571429rem 1rem;
}

.content_show_data_credit {
  margin: 0 2rem;
}

.content_data_credit {
  min-height: 50vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.content_description_view_data {
  line-height: 20px;
  padding: 2em 0 2em 0;
}
.title_description_view_data {
  color: var(--black-color);
  margin: 0;
  font-size: clamp(0.95em, 1.29em, 1.45em);
}

.sub_title_view_data {
  color: var(--gray-color);
  font-size: clamp(0.95em, 1.15em, 1.3em);
  font-weight: 400;
}

.btn_process_view_data {
  font-size: clamp(0.8em, 0.9em, 1em);
}

/* Label */
#custom_transition::before {
  left: 6%;
}

/* Verify nip */
.content_verify_nip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_custom {
  width: 400px;
}

.input_nip_custom {
  text-align: center !important;
  font-size: 1.5em !important;
  color: #575757 !important;
  letter-spacing: 1px;
}

.content_actions_verify_nip {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 1.3rem;
}

.content_actions_verify_nip > button {
  width: 40%;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
}

/*------- HistoryCredit styles ---------*/
.data_user {
  padding: 0 0 0.8em 0;
  margin-bottom: 1.6em;
  font-weight: 700;
  letter-spacing: 0.3px;
  border-bottom: 1px solid var(--gray-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_config {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.custom_field_data {
  width: 50%;
}
/*------- Ant design custom---------*/
.ant-typography-expand {
  color: #1890ff !important;
}

/* Modal */
.ant-modal-title {
  font-size: clamp(1em, 1.5em, 1.7em) !important;
  color: #172b4d;
  text-align: center;
}

/* Buttons */
#btn-actions_process-close,
#btn-actions_process-cancel {
  font-weight: 100;
  background-color: transparent;
  color: var(--light-pink-color);
  border: 1.9px solid var(--light-pink-color);
  transition: 0.3s color ease, 0.3s background-color ease;
  padding: 0.74rem 1rem;
}

#btn-actions_process-close:hover,
#btn-actions_process-close:active,
#btn-actions_process-close:focus,
#btn-actions_process-cancel:hover,
#btn-actions_process-cancel:active,
#btn-actions_process-cancel:focus {
  background-color: var(--light-pink-color);
  color: white;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

#btn-actions_process-submit {
  font-weight: 300;
  background-color: var(--light-purple-color);
  color: white;
  margin-left: 1em;
  transition: 0.3s color ease, 0.3s background-color ease;
}

#btn-actions_process-submit:hover,
#btn-actions_process-submit:active,
#btn-actions_process-submit:focus {
  background-color: var(--dark-purple-color);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

@media (max-width: 768px) {
  .form_custom {
    width: 300px;
  }
  .content_actions_verify_nip > button {
    width: 45%;
    padding: 0.7rem 0 !important;
  }
}

@media (orientation: landscape) {
  .form_custom {
    margin-bottom: 3em;
  }
}
