.containerResize {
  display: flex;
  width: 100%;
}

.divResize {
  width: 50%;
}

.content_info_settings_sign {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.96rem 0;
}

.labelSize {
  color: var(--gray-color);
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  margin-left: 2px;
}

.inputSize {
  border: 1px solid #d9d9d9;
  color: #4b4b4b;
  margin-right: 10px;
  outline: none;
  width: 70px;
  text-align: center;
}

.inputSize:hover,
.inputSize:active,
.inputSize:focus {
  border: 1px solid var(--secondary-color);
}
.inputImage {
  word-break: break-word;
  width: 270px;
  color: #888;
}
.containerReset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.2rem 0;
}

.buttonWhite,
.resetButton {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  outline: none !important;
  /* margin: 1.5rem 0.8rem 1rem 0rem; */
  background-color: white;
  width: 95px;
  height: 32px;
  border-radius: 3px;
}

.buttonWhite:hover,
.buttonWhite:active,
.buttonWhite:focus {
  outline: none !important;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: white;
}
.resetButton:hover,
.resetButton:active,
.resetButton:focus {
  outline: none !important;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
  color: white;
}
