.description_config_client {
  color: var(--gray-color);
}

.config_form {
  padding-top: 1.3em;
  padding-bottom: 1.5em;
}

.config_form__two-column {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.two_columns_data_form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.3em 0 0 0;
}

.form_data_description {
  display: flex;
  flex-direction: column;
}

.description_action_form {
  color: #a5a5a5;
  font-size: clamp(0.5rem, 0.85rem, 0.9rem);
  padding: 0 0.5rem 0 0;
}

.config_form__content_input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0.8em 0;
}

.lbl_input {
  color: var(--secondary-color);
  font-size: clamp(0.9em, 1em, 1.1em);
}

/* Country */
.select_country {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  /* border: 1px solid var(--secondary-color); */
  font-size: clamp(0.85em, 0.9em, 1em);
  padding: 0.55em 0.5em;
  max-width: 170px;
  transition: all 0.3s;
}

.select_country:hover,
.select_country:focus,
.select_country:active {
  border-color: var(--secondary-color);
  cursor: pointer;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

.select_country:disabled {
  cursor: not-allowed;
  color: #838383;
  background-color: rgba(224, 224, 224, 0.952);
  border: none;
  box-shadow: none;
}

.select_country > option {
  width: 150px;
}

/* Custom ant styles */
.config_form__content_input .ant-switch-checked {
  background-color: var(--secondary-color) !important;
}

.ant-collapse-content {
  background-color: #f6f8fa !important;
}

.config_form__content_input .ant-radio-checked .ant-radio-inner,
.config_form__content_input .ant-radio-wrapper:hover .ant-radio,
.config_form__content_input .ant-radio:hover .ant-radio-inner,
.config_form__content_input .ant-radio-wrapper:focus .ant-radio,
.config_form__content_input .ant-radio:focus .ant-radio-inner {
  border-color: var(--secondary-color) !important;
}

.config_form__content_input .ant-radio-inner::after {
  background-color: var(--secondary-color);
}

.two_columns_data_duration .ant-input:hover,
.two_columns_data_duration .ant-input:focus,
.two_columns_data_duration .ant-input-focused {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

.two_columns_data_form .ant-btn {
  background-color: #e9e9e9;
  border: none;
}

.two_columns_data_form .ant-btn:hover,
.two_columns_data_form .ant-btn:focus,
.two_columns_data_form .ant-btn:active {
  color: var(--black-color);
  background-color: #d3d3d3;
  /* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
  outline: none;
}

.content_data_message_sign .ant-input:focus,
.content_data_message_sign .ant-input:hover,
.content_data_message_sign .ant-input-focused {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

/* Modal */
.content_signer_cv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.input_signer_cv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.two_columns_modal_cv {
  display: grid;
  margin: 0;
  padding: 0;
  word-wrap: normal;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

.two_columns_modal_cv > div > .ant-switch,
.two_columns_modal_cv > .ant-switch {
  width: 30px;
}

.input_signer_cv > input {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.5em 0.7em;
  outline: none;
  transition: all 0.3s;
  margin-bottom: 1rem;
}

.input_signer_cv > input:hover,
.input_signer_cv > input:focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

.two_columns_modal_cv > div > .ant-switch-checked,
.two_columns_modal_cv > .ant-switch-checked {
  background-color: var(--secondary-color) !important;
}
