.ConsultationServicesHeader {
  background-color: #26285a;
  color: white;
}

.CardPhoneForm {
  display: flex;
  justify-content: space-between;
}

.CardPhoneSelect {
  width: 30%;
}

.CardPhoneInput {
  width: 60%;
}

.CardPhoneSocialContainer {
  display: flex;
  justify-content: space-between;
}

.CardPhoneSocial {
  display: flex;
  flex-direction: column;
}

.ButtonsCard {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.ButtonsCard > button {
  outline: none !important;
  border: none !important;
  background-color: var(--primary-color) !important;
  margin: 0;
  color: #fff !important;
  transition: all ease 600ms;
}

.ButtonsCard > button:hover,
.ButtonsCard > button:active,
.ButtonsCard > button:focus {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
}

#lbl_error_input {
  position: absolute;
  bottom: -38px;
  left: 0;
  width: auto;
}

input:disabled {
  cursor: not-allowed;
}

@media (max-width: 600px) {
  #lbl_error_input {
    position: relative !important;
    bottom: 0 !important;
  }
}

@media (min-width: 600px) and (max-width: 815px) {
  #lbl_error_input {
    position: relative !important;
    bottom: 0 !important;
  }
}

.error > input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important;
}

#my_msg > .close.icon {
  color: #9f3a38;
}
