.content_doc_postman {
  margin-top: 1em;
}
.content_doc_swagger {
  margin-top: 5em;
  margin-bottom: 4rem;
}
.content_doc_npm {
  margin-top: 5em;
  margin-bottom: 5rem;
}
/* Title */
.title_documentation {
  font-size: clamp(1.6em, 1.85em, 1.9em);
  letter-spacing: 0.5px;
  color: rgb(68, 68, 68);
}
/* Description */
.description_documentation {
  font-size: clamp(1em, 1.1em, 1.15em);
  color: gray;
}

/* Link */
.content_link_documentation {
  background-color: rgb(247, 247, 247);
  margin: 1.5rem 0 0 0;
  padding: 1rem 1rem;
  border: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
}

.content_link_documentation > .ant-typography {
  margin: 0;
  color: rgb(22, 22, 22);
}

.content_link_documentation > .ant-typography > .ant-typography-copy {
  padding: 0 10px !important;
}

.content_link_documentation > .open_new_tab {
  color: rgb(78, 75, 243);
}

.content_link_documentation > .open_new_tab:hover,
.content_link_documentation > .open_new_tab:active,
.content_link_documentation > .open_new_tab:focus {
  text-decoration: underline !important;
}

/* Tag */
.content_doc_postman > .ant-ribbon-wrapper,
.content_doc_swagger > .ant-ribbon-wrapper {
  width: 110px;
}

.content_doc_npm > .ant-ribbon-wrapper {
  width: 250px;
}

.content_doc_postman .ant-ribbon.ant-ribbon-placement-end,
.content_doc_swagger .ant-ribbon.ant-ribbon-placement-end,
.content_doc_npm .ant-ribbon.ant-ribbon-placement-end {
  top: -18px;
}

.ant-switch-checked {
  background-color: var(--primary-color) !important;
}

@media (max-width: 768px) {
  .content_doc_postman,
  .content_doc_swagger,
  .content_doc_npm {
    padding: 0 1.2rem;
  }
}
