.CarouselVerification {
  width: auto;
  display: flex;
  flex-direction: column;
}

.CarouselVerificationImages {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.FrontImageCarouselVerification {
  max-width: 21rem;
  max-height: 12rem;
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.slideshow-container h4 {
  color: #1e1b50;
   
}

/* Show image */
.slideEnable {
  display: block;
}

/* Hide image */
.slideDisable {
  display: none;
}

.dots {
  text-align: center;
  margin: 0.5rem 0 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

.ImagesCarouselVerification {
  max-width: 20.5rem;
  max-height: 10.5rem;
}

.ImagesCarouselVerification:hover {
  cursor: pointer;
}

@media only screen and (max-width: 670px) {
  .ImagesCarouselVerification {
    width: 10rem;
  }
}

@media only screen and (min-width: 670px) and (max-width: 1024px) {
  .ImagesCarouselVerification {
    width: 20rem;
  }
}
