.content_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_not_found {
  margin: 1.8em 0 0.1em 0;
  font-size: 1.4em;
  font-weight: 600;
}

.description_not_found {
  font-size: clamp(1em, 1.1em, 1.15em);
}

.return_home {
  padding: 1.2rem 0;
  outline: none;
  border: none;
  color: var(--btn-purple-color);
}

.return_home:hover,
.return_home:active,
.return_home:focus,
.return_home:focus-visible {
  color: var(--primary-color);
  text-decoration: underline;
}
