.title_header_contract {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: 0.2rem 0.2rem 0em 0em;
  padding: 0.78571429rem 1rem;
}

/* Custom collapse contract */
.data_contract__collapse_custom_panel {
  background: #f5f5f5;
  /* margin-bottom: 24px; */
  overflow: hidden;
  border: 0px;
  border-radius: 2px;
}

.ant-card-bordered {
  border-width: 1px !important;
}

/* Content data contract */
.title_panel_custom {
  font-weight: 600;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.content_status_general_sign {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-bottom: 2em;
  padding-left: 1rem;
  padding-right: 1rem;
}

.content_data_panel {
  margin-bottom: 1rem;
}

.content_status_verification_id > .description_panel_custom {
  padding-left: 3rem;
}
/* Content data author contract */
.content_data_author_contract {
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* padding-left: 1rem; */
  padding-right: 1rem;
}

.content_data_author_contract > p {
  font-weight: 700;
  letter-spacing: 0.1px;
}

.content_data_author_contract > p span {
  font-weight: normal;
}

/* Loading */
.content_loading_data_contract {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

/* Actions */
.btnContractClient {
  background: transparent;
  border-radius: 100%;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  transition: all 0.5s ease;
}
.btnContractClient:hover,
.btnContractClient:active,
.btnContractClient:focus {
  outline: none;
  background: rgba(189, 185, 255, 0.74);
  border-radius: 100%;
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  text-decoration: underline wavy;
}

.download_contract {
  background: transparent !important;
  border-radius: 100% !important;
  padding: 0.5rem 0.5rem 0.6rem 0.5rem !important;
  transition: all 0.5s ease;
}

.download_contract:hover,
.download_contract:active,
.download_contract:focus {
  outline: none;
  background: rgba(189, 185, 255, 0.74) !important;
  border-radius: 100%;
  padding: 0.5rem 0.5rem 0.6rem 0.5rem;
  text-decoration: underline wavy;
}

/* ---------------- Styles to ResultContract ------------------ */
.main_container_contract_client {
  width: 100%;
}

/* info client */
.content_info_contract {
  width: 100%;
}

.content_info_contract_part1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 2.5rem 0;
}

.content_info_contract_part2 {
  background-color: #f8f9fa;
  padding: 1rem 1.5rem;
  margin: 1rem 0 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* box-shadow: 0 1px 2px 0 #dfe3ec; */
  border: 0.5px solid #dfe3ec;
}

.content_data_client_contract {
  margin: 1rem 0;
}

.content_data_client_contract .ant-typography {
  color: gray;
}

.content_data_client_contract .ant-typography strong {
  font-weight: bolder;
}

.content_data_client_contract.extra_data_signer {
  margin-left: 50px;
}

.content_data_client_contract > h3 {
  font-weight: 600;
  color: var(--secondary-color);
}

.content_data_client_contract > p,
.content_data_client_contract > ul {
  color: gray;
}

/* PDF */
.content_contract_client {
  width: 100%;
}

.content_render_file {
  width: 100%;
  /* height: 900px; */
  padding: 50px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #e6e6e6;
  flex-direction: row;
  position: relative;
  z-index: 98;
  margin: 0 0 7rem 0;
}

.container_tools_pdf {
  background-color: var(--secondary-color);
  width: 100% !important;
  height: 50px;
  color: #eee;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.tools_pdf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tool_pdf {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.return_verification {
  padding: 0.6rem 0.9rem;
  border: none;
  outline: none;
  color: #fff;
  background-color: var(--secondary-color);
  border-radius: 3px;
}

.return_verification:hover,
.return_verification:active,
.return_verification:focus {
  background-color: #ff9c0b;
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}

/* Collapsed */
.btnCollapseData {
  margin: 1.8rem 0 0.8rem 0;
  border-radius: 3px;
  border: 1px solid #ffa932 !important;
  color: #ffa932;
  /* background-color: #ffa932; */
  background: transparent;
  padding: 0.5rem 1rem;
  outline: none;
  border: 0;
}

.btnCollapseData:hover,
.btnCollapseData:active {
  background-color: #ffa318;
  color: #fff;
}

.collapse-content.collapsed {
  display: none;
}

.collapsed-content.expanded {
  display: block;
}

.btnSwitch .ant-switch-checked {
  background-color: var(--secondary-color) !important;
}

@media (max-width: 768px) {
  .content_data_client_contract.extra_data_signer {
    margin-left: 0;
  }
}
