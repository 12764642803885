.actions_add {
  width: 100%;
  height: 45px;
  margin-top: 7px;
}

.actions_add button {
  border: none;
  padding: 0.25rem 0.5rem 0.35rem 0.5rem;
  border-radius: 5px;
}

.new_add {
  background-color: var(--secondary-color);
  margin-right: 10px;
}

.new_add:hover,
.new_add:active,
.new_add:focus {
  background-color: var(--secondary-color);
}

.cancel_add {
  background-color: #ff6363;
}

.cancel_add:hover,
.cancel_add:active,
.cancel_add:focus {
  background-color: #ff4141;
}

.cancel_add:disabled {
  background-color: #cf4747;
}
