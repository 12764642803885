.SecurityFeatures {
  margin: 1rem;
}

.TableSecurityFeatures {
  width: 96%;
  margin: 0 1.5rem;
  background: #fff 0% 0% no-repeat padding-box;
  border-bottom-style: solid;
  border-collapse: collapse;
  opacity: 1;
   
}

.SecurityFeaturesRows {
  border-bottom-style: outset;
  text-align: center;
  padding: 8px;
  color: #1e1b50;
   
  font-weight: normal;
  font-size: 1rem;
}

.ThSecurityFeatures {
  border-top-style: solid;
  border-bottom-style: solid;
  text-align: center;
  padding: 8px;
  color: #1e1b50;
   
  font-weight: normal;
  font-size: 1rem;
}

.EditButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.OverwriteButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #738d00;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.CancelButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.SecurityFeaturesOutput:hover {
  cursor: pointer;
}
