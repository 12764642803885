.removeButtonTerms {
  border: 1px solid #f03b3be5;
  color: #f03b3be5;
  outline: none !important;
  background-color: white;
  border-radius: 3px;
  margin-left: 1rem;
  padding: 0 1rem;
}
.removeButtonTerms:hover,
.removeButtonTerms:active,
.removeButtonTerms:focus {
  border-color: #f03b3be5;
  background-color: #f03b3be5;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
  color: white;
}

.removeButtonTerms:disabled {
  background-color: rgb(211, 211, 211) !important;
  border-color: rgb(211, 211, 211) !important;
  color: #979797 !important;
}

.textarea_custom {
  outline: none !important;
  border: 1px solid #d9d9d9 !important;
  color: grey !important;
  margin-bottom: 9px;
  outline: none;
  min-height: 35px;
}

.textarea_custom:hover,
.textarea_custom:active,
.textarea_custom:focus {
  border: 1px solid var(--secondary-color) !important;
}
