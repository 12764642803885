.Main {
  margin: 1rem 0;
}

.Uuid {
  margin: 0 0 1rem;
  display: flex;
}

.CopyUuid {
  max-width: 20rem;
}

.Documents {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: space-between;
}

.Obtained {
  width: 45%;
  display: flex;
  /* flex-direction: column; */
}

.Reference {
  width: 45%;
  display: flex;
}

.FilterDocuments {
  display: flex;
  flex-direction: column;
  margin: 4em 0 0 0;
  color: #1e1b50;
  font-weight: normal;
}

.FilterDocumentsOptions {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-auto-flow: dense;
  width: 100%;
  margin: 0;
}

.FDoptions {
  /* width: 33%; */
  margin: 1rem 0.6rem;
  display: flex;
  flex-direction: column;
}

.FDinput {
  /* width: 34%; */
  margin: 1rem 0.6rem;
  display: flex;
  flex-direction: column;
}

.SelectTemplate {
  height: 1.8rem;
  width: 97%;
  margin: 0.7rem 0 0;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}

.InputTemplate {
  height: 1.8rem;
  width: 100%;
  margin: 0.7rem 0 0;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}

.ButtonFilterTemplate {
  align-self: flex-end;
  justify-content: center;
  padding: 0.5rem 2rem;
  background-color: #3f43b8;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 0.3rem;
}

.ButtonFilterTemplate:hover,
.ButtonFilterTemplate:active,
.ButtonFilterTemplate:focus {
  outline: none;
  background-color: #2d308a;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.ButtonFilterTemplate:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.AvailableDocuments {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  color: #1e1b50;
  font-weight: normal;
}

.ImageTemplate {
  max-width: 11rem;
  height: 7rem;
  border: none;
  outline: none;
  object-fit: contain;
}

.ImageTemplate:hover,
.ImageTemplate:active,
.ImageTemplate:focus {
  outline: none;
  border: 4px outset rgba(64, 24, 255, 0.47);
  cursor: pointer;
}

.ButtonSelect {
  padding: 0.5rem 2rem;
  margin: 2rem 0 0;
  background-color: #96b410;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.ButtonSelect:hover,
.ButtonSelect:focus,
.ButtonSelect:active {
  background-color: #87a112;
  outline: none;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.ButtonSelect:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.EditImagesOption {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  color: #1e1b50;
  font-weight: normal;
}

.UploadImagesOption {
  display: flex;
  flex-direction: column;
  margin: 3em 0;
  color: #1e1b50;
}

.Comments {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 7rem;
  color: #1e1b50;
  font-weight: normal;
}

.TitleClickable {
  /* text-decoration: underline; */
  color: #303030;
  font-size: clamp(1em, 1.1em, 1.2em);
  padding-bottom: 1em;
}

.SelectComments {
  height: 1.8rem;
  width: 100%;
  margin: 0.7rem 0 0;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
}

.Buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: row-reverse;
  margin: 1.8rem 0 0;
}

.ButtonAddFace {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #2f363c;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonAddFace:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonDownload {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #3f43b8;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  margin: 0.6rem 0.5rem;
}

.ButtonDownload:hover,
.ButtonDownload:active,
.ButtonDownload:focus {
  outline: none;
  background-color: #2d308a;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.ButtonVerify {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #96b410;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  margin: 0.6rem 0.5rem;
}

.ButtonVerify:hover,
.ButtonVerify:focus,
.ButtonVerify:active {
  background-color: #87a112;
  outline: none;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.ButtonVerify:disabled {
  background-color: #75813e;
  cursor: not-allowed;
}

.ButtonNotProcess {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #c10841;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  margin: 0.6rem 0.5rem;
}

.ButtonNotProcess:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonFake {
  padding: 0.5rem 2rem;
  background-color: #c10841;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  margin: 0.6rem 0.5rem;
}

.ButtonFake:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.mockInfo {
  display: flex;
  flex-direction: column;
}

.mockDimensions {
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) and (min-width: 767px) {
  .Obtained {
    width: 80%;
    margin: 0 auto 2.5rem auto;
  }
  .Reference {
    margin: 0 auto;
    width: 80%;
  }
}

@media (max-width: 767px) {
  .Obtained {
    margin: 0 auto 2.5rem auto;
    width: 100%;
  }

  .Reference {
    margin: 0 auto;
    width: 100%;
  }
}
