@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Plus+Jakarta+Sans:wght@200;300&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
label,
button {
  font-family: 'Montserrat', sans-serif, -apple-system, 'Roboto', 'Ubuntu' !important;
}

button:disabled {
  pointer-events: visible !important;
  cursor: not-allowed !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Reset values */
.ui.label {
  font-weight: normal;
}

.ui.error.message {
  color: #9f3a38 !important;
}

.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover {
  pointer-events: visible !important;
  cursor: not-allowed !important;
}

/* Config general */
.btns_actions_with_background_yellow {
  height: 2.8rem;
  border: none;
  color: white !important;
  background-color: var(--secondary-color) !important;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  padding: 0 1rem;
  transition: background-color ease 0.3s, color ease 0.3s !important;
}

.btns_actions_with_background_yellow:hover,
.btns_actions_with_background_yellow:active,
.btns_actions_with_background_yellow:focus {
  background-color: var(--secondary-color) !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
  outline: none !important;
}

.btns_actions_not_background {
  height: 2.8rem;
  background: transparent !important;
  border: 1.8px solid var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  padding: 0 1rem;
  font-weight: 600;
  transition: background-color ease 0.3s, color ease 0.3s;
}

.btns_actions_not_background:hover,
.btns_actions_not_background:active,
.btns_actions_not_background:focus {
  background-color: var(--secondary-color) !important;
  color: white !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}

.btns_actions_with_background {
  height: 2.8rem;
  background: transparent;
  border: none;
  color: white !important;
  background-color: var(--primary-color) !important;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  padding: 0 1rem;
  transition: background-color ease 0.3s, color ease 0.3s !important;
}

.btns_actions_with_background:hover,
.btns_actions_with_background:active,
.btns_actions_with_background:focus {
  background-color: var(--primary-color) !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
  outline: none !important;
}

.ui.attached.header {
  border: none;
}

.btns_actions_primary_not_background {
  height: 2.8rem;
  background: transparent !important;
  border: 1.8px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  padding: 0 1rem;
  font-weight: 600;
  transition: background-color ease 0.3s, color ease 0.3s;
}

.btns_actions_primary_not_background:hover,
.btns_actions_primary_not_background:active,
.btns_actions_primary_not_background:focus {
  background-color: var(--primary-color) !important;
  border-color: var(--btn-hover-purple-color) !important;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2) !important;
  outline: none !important;
  color: white !important;
}

.custom_input_form_search_doc .ant-select-selector {
  height: 37px !important;
  padding-top: 3px !important;
  outline: none !important;
}
.custom_input_form_search_doc > .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.custom_input_form_search {
  outline: none !important;
}

.custom_input_form_search::placeholder {
  color: rgb(124, 124, 124) !important;
}

.custom_input_form_search:hover,
.custom_input_form_search:active,
.custom_input_form_search:focus,
.custom_input_form_search_doc:hover,
.custom_input_form_search_doc:active,
.custom_input_form_search_doc:focus {
  border-color: #d9d9d9 !important;
  box-shadow: 0 0 0 3px rgba(24, 143, 255, 0.116) !important;
}

.btn_actions_custom_filter {
  height: 2.8rem;
  border: none;
  background: transparent !important;
}

.btn_export_data {
  color: #269640 !important;
  background: transparent !important;
  border: solid 1px #269640 !important;
}

.btn_export_data:hover,
.btn_export_data:active,
.btn_export_data:focus {
  color: #fff !important;
  outline: none !important;
  background-color: #269640 !important;
  box-shadow: 0 0 0 3px rgba(24, 143, 255, 0.116) !important;
}

.btn_clear_data {
  background: none !important;
  color: var(--primary-color) !important;
}

.btn_search_data {
  color: #fff !important;
  border: solid 1px var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.btn_search_data:hover,
.btn_search_data:active,
.btn_search_data:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(24, 143, 255, 0.116) !important;
}
