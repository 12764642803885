.TitleTemplate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ButtonTemplate {
  width: 6.5rem;
  height: 2.5rem;
  background-color: #23265a;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.LeftArrow {
  width: 1rem;
  margin-right: 0.5rem;
}
