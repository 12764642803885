.mainLi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: 10px 0;
  border: 1px solid #c4c4c4;
  font-size: clamp(0.75rem, 0.8rem, 0.85rem);
}

.mainLi:hover,
.mainLi:active,
.mainLi:focus {
  border: 1px solid var(--secondary-color);
}

.mainInformationDiv {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.informationDiv {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  padding: 0 1.1rem;
  color: #646464;
}

.deleteDiv {
  width: 20%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteButton {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 2px;
  color: #ff6363;
  padding: 0rem 0.5rem 0.2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0.5px solid #ff6363;
  transition: all ease 1.5ms;
}

.deleteButton:hover,
.deleteButton:active,
.deleteButton:focus {
  background-color: #ff6363;
  color: #fff;
  outline: none;
}

.optionsDiv {
  width: 100%;
  margin: 1rem 0 0 0;
}

.optionsDivIdentity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #3b3b3b;
}

.spanOptionsText {
  margin: 0 0 0 1rem;
}

.optionsDivIdentity > input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 17px;
  height: 17px;
  border: 1.4px solid #c5c5c5;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.optionsDivIdentity > input[type='checkbox']:checked {
  background-color: #ffa826;
  outline: none;
}
.optionsDivIdentity > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: #fff;
}

.optionsDivIdentity > input[type='checkbox']:hover,
.optionsDivIdentity > input[type='checkbox']:focus,
.optionsDivIdentity > input[type='checkbox']:active {
  outline: none;
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(255, 193, 24, 0.2);
}
.optionsDivIdentity > input[type='checkbox']:checked {
  background-color: #ffa826;
  outline: none;
}

.optionsDivIdentity > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.optionsDivIdentity > input[type='checkbox']:disabled {
  outline: none;
  cursor: not-allowed;
}
