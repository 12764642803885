.VerificationResume {
  margin: 0;
}

.TableVerificationResume {
  width: 100%;
  margin: 0;
  background-color: #ebebeb;
}

.VerificationResumeRows {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: left;
  padding: 8px;
}

.ThVerificationResume {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: left;
  padding: 8px;
  color: #1e1b50;
  font-weight: 500;
}

.VerificationResumeOutput:hover {
  cursor: pointer;
}
