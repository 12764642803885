.container_biometrics {
  /* width: 100%; */
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  /* padding: 1rem 0; */
}

.container_biometrics p {
  padding: 0 0 1rem 0;
  margin: 0;
}

.content_portrait,
.content_selfie,
.content_result {
  text-align: center;
  padding: 1rem 0;
}

/* Portrait  & Selfie*/
.ctnt_image__portrait,
.ctnt_image__selfie,
.ctnt_image_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.ctnt_image__portrait > img,
.ctnt_image__selfie > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* Reset styles ant-design */
.ctnt_image__selfie > .ant-image {
  width: 100%;
  height: 100%;
}

.ctnt_image__selfie > .ant-image > img {
  height: 100%;
  object-fit: cover;
}

.ButtonApprove {
  width: 6.5rem;
  height: 2.5rem;
  background-color: #738d00;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonReject {
  width: 6.5rem;
  height: 2.5rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type='number'] {
  -moz-appearance: textfield;
} */
