.FaceFraudList {
  margin: 0;
}

.TableFaceFraudList {
  width: 100%;
  margin: 0;
  background-color: #ebebeb;
  border-collapse: collapse;
}

.FaceFraudListRows {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: center;
  padding: 8px;
  font-size: 1rem;
}

.ThFaceFraudList {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: center;
  padding: 8px;
  color: #1e1b50;
  font-weight: 500;
}

.EditButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.OverwriteButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #738d00;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.OverwriteButton:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonImages {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonImages:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.CancelButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #c10841;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
}

.FaceFraudListOutput:hover {
  cursor: pointer;
}
