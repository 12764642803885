.Main {
  margin: 1rem 0;
}

.Uuid {
  margin: 0 0 1rem;
  display: flex;
}

.CopyUuid {
  max-width: 20rem;
}

.Documents {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: space-between;
}

.Obtained {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.Reference {
  width: 45%;
  display: flex;
}

.Options {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.Buttons {
  display: flex;
  flex-direction: row-reverse;
  margin: 1.8rem 0 0;
  flex-wrap: wrap;
}

.ButtonSend {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonChecked {
  padding: 0.5rem 2rem;
  background-color: #738d00;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonAccepted {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #738d00;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonAccepted:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonRejected {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonRejected:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonFalsePositive {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #c10841;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonFalsePositive:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonAddFace {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #2f363c;
  color: #fff;
   
  border: none;
  border-radius: 0.3rem;
}

.ButtonAddFace:disabled {
  background-color: #22255780;
  cursor: not-allowed;
}

.ButtonDownload {
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: #3f43b8;
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  margin: 0.6rem 0.5rem;
}

.ButtonDownload:hover,
.ButtonDownload:active,
.ButtonDownload:focus {
  outline: none;
  background-color: #2d308a;
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.2);
}u

.Comments {
  display: flex;
  flex-direction: column;
  margin: 3rem 0 7rem;
  color: #1e1b50;
  font-weight: normal;
}

@media (max-width: 900px) and (min-width: 767px) {
  .Obtained {
    width: 80%;
    margin: 0 auto 2.5rem auto;
  }
  .Reference {
    margin: 0 auto;
    width: 80%;
  }
}

@media (max-width: 767px) {
  .Obtained {
    margin: 0 auto 2.5rem auto;
    width: 100%;
  }

  .Reference {
    margin: 0 auto;
    width: 100%;
  }
}
