@import '~antd/dist/antd.css';
@import '~react-multi-carousel/lib/styles.css';

:root {
  --dashboard-color: #eff1f5;
  --primary-color: #373a8a;
  --secondary-color: #faa82c;
  --purple-color: #2f3176;
  --gray-color: #7c7c7c;
  --btn-purple-color: #373a8a;
  --btn-hover-purple-color: #3e4a86d5;
  --black-color: #272727;
  --black-light-color: #5a5a5a;
  --smooth-color: #f7f7f7;
  /* Modal Button Colors*/
  --light-purple-color: #4e52d8;
  --dark-purple-color: #34379c;
  --light-pink-color: #ff84ad;
  --btn-yellow-color: #faa82c;
  --btn-hover-yellow-color: #ff9f31;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#modalRevision {
  display: flex !important;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1640px;
  }
}

/* Edit semantic ui */
.ui.selectable.table tr.positive:hover,
.ui.table tr td.selectable.positive:hover,
.ui.selectable.table tr:hover td.positive {
  background-color: #e6fcbf !important;
}

.ui.selectable.table tr.positive:hover,
.ui.table tr td.selectable.positive:hover,
.ui.selectable.table tr:hover td.positive {
  background-color: #e6ffbd !important;
}

.ui.selectable.table tr.warning:hover,
.ui.table tr td.selectable.warning:hover,
.ui.selectable.table tr:hover td.warning {
  background-color: #fdf2c1 !important;
}

.ui.table tr.warning,
.ui.table td.warning {
  background: #fdf8dc !important;
}

/* Edit dropdown css */
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f1f1f1 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #3b8de6;
  color: white;
  outline: none;
  border: none;
}

.nav-item.dropdown .dropdown-menu {
  box-shadow: 0 2px 4px -8px rgba(26, 25, 25, 0.24),
    0 5px 16px 0 rgba(21, 21, 21, 0.16), 0 9px 28px 2px rgba(34, 34, 34, 0.11);
  border: none;
}
.nav-item.dropdown .dropdown-item {
  border: none;
}
.dropdown-menu {
  padding: 0.5rem 0 0 0;
}
.ui.page.dimmer {
  perspective: none !important;
}

@media (max-width: 767px) {
  /* Edit auth0.css */
  .navbar-nav .user-info img {
    width: 30px;
  }

  .navbar-nav:last-child li .active {
    background-color: white;
  }

  .navbar-light .navbar-toggler:hover,
  .navbar-light .navbar-toggler:active,
  .navbar-light .navbar-toggler:focus,
  .navbar-light .navbar-toggler:focus-visible {
    border-color: gray;
    outline: none;
  }

  .content_pagination_table_custom .content_feature_to_pagunation {
    width: 100%;
    display: flex;
    margin: 0 0 1.3rem;
    align-items: center;
    justify-content: center;
  }
  .content_pagination_table_custom div:nth-child(2) {
    margin: 0 auto !important;
    overflow: auto !important;
    width: 90%;
  }
}

/* Reset icon color */
.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: #fff !important;
}

.css-dx096b.Mui-active .MuiTableSortLabel-icon {
  color: #fff !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #4e52d8 !important;
  border-color: #4e52d8 !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff !important;
}

.ant-radio-button-wrapper:hover {
  color: #4e52d8 !important;
}

.ant-table-thead {
  background-color: var(--primary-color) !important;
}

.ant-table-thead .ant-table-cell {
  font-size: clamp(0.9rem, 0.97rem, 0.99rem) !important;
  color: #fff !important;
  font-weight: 700;
  background: none;
}

.tableData .ant-switch-checked {
  background-color: var(--primary-color) !important;
}

.biometric_content_main > .ant-image {
  margin-right: 1rem;
}

/* tables custom */
.head_table_custom {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: none !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: 0.97rem !important;
}

.body_cell_table_custom {
  text-align: center !important;
  font-size: clamp(0.95rem, 0.97rem, 1rem) !important;
}

.content_pagination_table_custom {
  margin: 1.4rem 0 3.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.content_feature_to_pagunation {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.content_feature_to_pagunation p {
  margin: 0;
  padding: 0;
}
.select_limit_to_pagination {
  border: none;
  margin: 0 0.5rem;
  padding: 0.3rem;
  background-color: #f7f7f7;
  border-radius: 10px;
}
