.ControlList {
  margin: 1rem;
}

.TableControlList {
  width: 96%;
  margin: 0 1.5rem;
  background: #fff 0% 0% no-repeat padding-box;
  border-bottom-style: solid;
  border-collapse: collapse;
  opacity: 1;
}

.ObjectControlList {
  width: 96%;
  margin: 0 1.5rem;
  background: #fff 0% 0% no-repeat padding-box;
  border-collapse: collapse;
  opacity: 1;
}

.ObjectRowsTitle {
  border-bottom-style: outset;
  border-left-style: outset;
  border-right-style: outset;
  text-align: left;
  padding: 8px;
  color: #1e1b50;
  font-weight: normal;
  font-size: 1rem;
  width: 8rem;
}

.ObjectRowsValue {
  border-bottom-style: outset;
  border-left-style: outset;
  border-right-style: outset;
  text-align: left;
  padding: 8px;
  color: #1e1b50;
  font-weight: normal;
  font-size: 1rem;
  width: 15rem;
}

.ControlListValue {
  width: 13rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ControlListValue:hover {
  overflow: visible;
}

.ControlListRows {
  border-bottom-style: outset;
  text-align: center;
  padding: 8px;
  color: #1e1b50;

  font-weight: normal;
  font-size: 1rem;
}

.ThControlList {
  border-top-style: solid;
  border-bottom-style: solid;
  text-align: center;
  padding: 8px;
  color: #1e1b50;

  font-weight: normal;
  font-size: 1rem;
}

.EditButtons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.OverwriteButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #738d00;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}

.CancelButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: #c10841;
  color: #fff;

  border: none;
  border-radius: 0.3rem;
}

.ControlListOutput:hover {
  cursor: pointer;
}
