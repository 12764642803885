.DocumentData {
  margin: 0;
}

.TableDocumentData {
  width: 100%;
  margin: 0;
  background-color: #ebebeb;
}

.RowsDocumentData {
  width: 50%;
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: left;
  padding: 8px;
}

.ThDocumentData {
  border-bottom: 1px solid rgb(148, 148, 148);
  text-align: left;
  padding: 8px;
  color: #1e1b50;
  font-weight: 500;
}

.EditButton {
  margin: 1rem 0;
  background-color: #23265a;
  color: #fff;
  border: none;
  border-radius: 0.2rem;
  padding: 0.6rem 0.8rem;
}
