.content_data_process_belvo {
  min-height: 50vh;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 600px) {
  .content_actions {
    margin-top: 1.3rem;
  }
  .content_actions > .ant-picker-range {
    margin-bottom: 1rem;
  }
}
